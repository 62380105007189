import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Img from "gatsby-image"

import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/hero-image"

const styles = {
  slide: {
    position: "relative",
    color: "#fff",
  },
  slide1: {
    overflow: "hidden",
  },
  slide2: {
    overflow: "hidden",
  },
  slide3: {
    overflow: "hidden",
  },
  slide4: {
    overflow: "hidden",
  },
  slide5: {
    overflow: "hidden",
  },
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    filter: brightness(0.8);
  }
`

const TitleBlock = styled.div`
  margin: 60px 0px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div``

const LightBlueSpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightCol};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin: 16px 0;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.lightText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(33% - 16px);
  margin: 10px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const LinkTitle = styled.a`
  font-size: 25px;
  font-weight: bold;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.mainBlue} !important;
  background-color: transparent !important;

  :hover {
    filter: drop-shadow(rgba(255, 255, 255, 0.9) 2px 2px 5px) !important;
  }
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        deckensektionaltore: file(name: { eq: "superior_antra" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        seitensektionaltore: file(name: { eq: "SektionalTore-Edit" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        deckensektionaltore2: file(name: { eq: "DST_Smart_Style_Eiche_Hell_Woodgrain" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "superior" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage2: file(name: { eq: "smart" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage3: file(name: { eq: "seitensektionaltor" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/DSC_2280|DSC_2313|HIG_7398|Normstahl_IT0005/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
      <Helmet>
        <link rel="canonical" href="https://normstahl.com/de-de/garagentore/sektionaltore" />
      </Helmet>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
          <AutoPlaySwipeableViews interval="6000">

            <div style={Object.assign({}, styles.slide, styles.slide3)}>
              <ImageWrapper>
                <Img fluid={props.deckensektionaltore.childImageSharp.fluid} />
              </ImageWrapper>
            </div>

            <div style={Object.assign({}, styles.slide, styles.slide2)}>
              <ImageWrapper>
                <Img fluid={props.seitensektionaltore.childImageSharp.fluid} />
              </ImageWrapper>
            </div>

            <div style={Object.assign({}, styles.slide, styles.slide3)}>
              <ImageWrapper>
                <Img fluid={props.deckensektionaltore2.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </AutoPlaySwipeableViews>


          <Margins>
            <ProductDescriptionBlock>
              <Flex direction={"column"}>
                <TitleBlock>
                  <h1> Sektionaltore bieten viele Vorteile …</h1>
                  <p>
                    Ein Normstahl Sektionaltor hat gegenüber dem Kipptor, dem
                    Flügeltor und dem Schwingtor den Vorteil, dass es nach außen
                    keinen Raum zum Öffnen benötigt. Die Sektionen sind
                    aneinandergefügt und werden in Schienen geführt. Damit ist
                    das Tor nach dem Öffnen in einer ebenen Position unter
                    Garagendecke oder an der Garagenwand. Wir sind von der
                    Qualität unserer Sektionaltore überzeugt und geben daher
                    eine 10 Jahre Vollgarantie – Verschleißteile und
                    Toroberfläche inklusive!
                    <br />
                  </p>
                </TitleBlock>
              </Flex>
            </ProductDescriptionBlock>
          </Margins>

          <BoxComponent>
            <Margins>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <LinkTitle href="/deckensektionaltore">
                      Deckensektionaltore
                    </LinkTitle>
                    <p>
                      Besonders beliebt: Deckensektionaltore öffnen sich
                      senkrecht nach oben und sind dadurch sehr platzsparend. So
                      haben Sie auch vor der Garage freie Bahn. Durch optional
                      erhältlichen Einbruchschutz hat sich diese Art des
                      modernen Garagentors bei Langfingern nachhaltig unbeliebt
                      gemacht. Doch auch die Vielfalt an Garagentor-Modellen
                      weiß zu begeistern.
                    </p>
                  </TextBlock>
                </LowerBox>
              </LowerBoxWrapper>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <LinkTitle href="/seitensektionaltore">
                      Seitensektionaltore
                    </LinkTitle>
                    <p>
                      Die praktische Alternative: Je nach den baulichen
                      Gegebenheiten schaffen diese Tore Platz nach oben und
                      ermöglichen bei einer Teilöffnung das sofortige Betreten
                      der Garage. Wie auch beim Deckensektionaltor sind die
                      Paneele äußerst energieeffizient und der Kreativität im
                      Hinblick auf die Gestaltungsfreiheit werden kaum Grenzen
                      gesetzt.
                    </p>
                  </TextBlock>
                </LowerBox>
              </LowerBoxWrapper>

              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>Normstahl Superior</Title>
                    <p>Unser Premium Tor</p>
                    <a href="https://normstahl.com/de-de/garagentore/sektionaltore/deckensektionaltore" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="superior"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>Normstahl Smart</Title>
                    <p>Das qualitative Tor für jedermann.</p>
                    <a href="https://normstahl.com/de-de/garagentore/sektionaltore/deckensektionaltore" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage2.childImageSharp.fluid}
                      alt="smart"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>
                      Normstahl Seiten
                      <wbr />
                      sektionaltor
                    </Title>
                    <p>Maximaler Raum unter der Decke</p>
                    <a href="https://normstahl.com/de-de/garagentore/sektionaltore/seitensektionaltore">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage3.childImageSharp.fluid}
                      alt="smart"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
            </Margins>
          </BoxComponent>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
